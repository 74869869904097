
import RepairIcon from '@/components/common/RepairIcon.vue';
import { Product } from '@/store/models/Product';
import { formatHours } from '@/utils/DateUtils';
import {
  formatAgeMilk,
  formatMilkScore,
  formatMilkScoreAbbr
} from '@/utils/TableFormatters';
import { mixins } from 'vue-class-component';
import { Component, Inject, Prop, PropSync } from 'vue-property-decorator';
import AppName from '../mixin/AppName.vue';
import { PermissionService } from '@/services/PermissionService';

@Component({
  components: {
    RepairIcon
  },
  methods: {
    formatAgeMilk,
    formatMilkScore,
    formatMilkScoreAbbr,
    formatHours,
  }
})
export default class MilkAlertsCardList extends mixins(AppName) {
  @PropSync('items') sortedProducts!: Product[];
  @Prop() loadingProducts!: boolean;

  @Inject('permissionService')
  private permissionService!: PermissionService;

  public get canActionAlerts(): boolean {
    return this.permissionService.canActionAlerts;
  }

  public onAction(item: Product): void {
    if (!this.canActionAlerts) {
      return;
    }
    this.openActionModal(item);
  }

  public onEdit(item: Product): void {
    if (!this.canActionAlerts) {
      return;
    }
    this.openEditModal(item);
  }

  public didSelectCard(selectedItems: Product[], id: number, entityId: number) {
    this.$emit('card-selected', selectedItems, id, entityId);
  }

  public openActionModal(product: Product) {
    this.$emit('openActionModal', product);
  }

  public openEditModal(product: Product) {
    this.$emit('openEditModal', product);
  }
}
