import { UpdateCustomLabelIdData } from '@/services/api/CustomFieldApi';
import TableColumn, { TableColumnFormatters } from '@/store/models/TableColumn';
import User from '@/store/modules/User';
import { getAppName } from './AppName';
import { Constants } from './Constants';
import {
  GetSiteCustomColumns,
  UpdateCustomColumnsVisibility
} from './CustomColumns';
import {
  getObjectItem,
  getObjectListItem,
  removeUserItem,
  setObjectItem
} from './LocalStorageUtils';
import { getSpecificPageName } from './PageUtils';
import { GetDefaultFeedColumns } from './table-fields/FeedTableFields';
import { GetDefaultFuelColumns } from './table-fields/FuelTableFields';
import { GetDefaultMilkColumns } from './table-fields/MilkTableFields';
import { productViewFields } from './table-fields/TableFieldLists';
import { GetDefaultColumns } from './table-fields/TableFields';
import { UpdateTableColumnsVisibility } from './TableColumnVisibility';
import { AllowedField, MilkAllowedField } from './types/AllowedFields';
import { productType } from './types/ProductType';
import { StringKeyObject } from './types/StringKeyObject';
import { GetDefaultWaterColumns } from './table-fields/WaterTableFields';

export function UpdateDefaultTableColumnsVisibility(
  fieldsVisibility: StringKeyObject | TableColumn[]
) {
  let defaultFieldsVisibility: StringKeyObject = {};
  if (Array.isArray(fieldsVisibility)) {
    fieldsVisibility.forEach(field => {
      defaultFieldsVisibility[field.name] = field.visible;
    });
  } else {
    defaultFieldsVisibility = fieldsVisibility;
  }
  setObjectItem(
    `${getAppName(false)}${getSpecificPageName()}TableColumnsVisibility`,
    defaultFieldsVisibility
  );
}

export function GetDefaultTableColumns() {
  let defaultColumns: {
    [key: string]: TableColumnFormatters;
  };
  switch (getAppName(false)) {
    case Constants.PRODUCT_TYPE_MILK:
      defaultColumns = GetDefaultMilkColumns();
      break;
    case Constants.PRODUCT_TYPE_FUEL:
      defaultColumns = GetDefaultFuelColumns();
      break;
    case Constants.PRODUCT_TYPE_FEED:
      defaultColumns = GetDefaultFeedColumns();
      break;
    case Constants.PRODUCT_TYPE_WATER:
      defaultColumns = GetDefaultWaterColumns();
      break;
    default:
      defaultColumns = GetDefaultColumns();
      break;
  }

  let tableSpecificFieldsFromItem = getObjectItem(
    `${getAppName(false)}${getSpecificPageName()}TableColumnsVisibility`
  );
  const tableFieldsFromItem = getObjectItem(
    `${getAppName(false)}TableFieldsVisibility`
  );
  if (tableSpecificFieldsFromItem || tableFieldsFromItem) {
    //New format has been saved
    if (!tableSpecificFieldsFromItem && tableFieldsFromItem) {
      //Page specific custom fields have not been saved, but there are non page specifc visibilty set
      tableSpecificFieldsFromItem = tableFieldsFromItem;
      //save visibility settings to page specific settings
      UpdateTableColumnsVisibility(tableFieldsFromItem);
    }
    Object.entries(defaultColumns).forEach(field => {
      const visibility =
        (tableSpecificFieldsFromItem as StringKeyObject)[field[1].name] ?? true;
      defaultColumns[field[0]].visible = visibility;
    });
    return defaultColumns;
  } else {
    const oldTableFieldsFromItem = (
      getObjectListItem(`${getAppName(false)}TableFields`) ?? []
    ).filter(column => column.name.includes('Default'));
    if (oldTableFieldsFromItem.length != 0) {
      //Old format is saved,  convert to new format, save it, use it to update visibilty of fields, delete old format
      const defaultFieldsVisibility: StringKeyObject = {};
      oldTableFieldsFromItem.forEach(field => {
        defaultFieldsVisibility[field.name] = field.visible;
      });
      Object.entries(defaultColumns).forEach(field => {
        const visibility = defaultFieldsVisibility[field[1].name] ?? true;
        defaultColumns[field[0]].visible = visibility;
      });
      //save new visibility
      UpdateDefaultTableColumnsVisibility(defaultFieldsVisibility);

      //delete old save visibility object
      removeUserItem(`${getAppName(false)}TableFields`);
      return defaultColumns;
    } else {
      //No saved fields for this partner, need to save
      const defaultFieldsVisibility: StringKeyObject = {};
      Object.entries(defaultColumns).forEach(field => {
        defaultFieldsVisibility[field[0]] = field[1].visible;
      });

      UpdateDefaultTableColumnsVisibility(defaultFieldsVisibility);
      return defaultColumns;
    }
  }
}
