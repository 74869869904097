
import FilterTextInput from '@/components/filters/FilterTextInput.vue';
import { milkGlobals } from '@/store/Globals';
import CustomField from '@/store/models/CustomField';
import { MilkFilters, SavedMilkFilters } from '@/store/models/Filters';
import CustomStore from '@/store/modules/CustomStore';
import { MilkFiltersClass } from '@/utils/classes/MilkFiltersClass';
import { complianceMethodLabel } from '@/utils/MilkScoreUtils';
import { cloneDeep } from 'lodash';
import { mixins } from 'vue-class-component';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import AppName from '../mixin/AppName.vue';

@Component({
  components: { FilterTextInput },
  methods: { complianceMethodLabel },
  computed: {
    getHasNonFonterra() {
      return milkGlobals.hasNonFonterra;
    },
    ...mapGetters('custom', {
      complianceMethods: 'complianceMethods'
    })
  }
})
export default class MilkFiltersModal extends mixins(AppName) {
  @Prop() selectedPropSiteFilters!: {
    field: CustomField;
    selection: string[];
  }[];
  @Prop() selectedPropVatFilters!: {
    field: CustomField;
    selection: string[];
  }[];
  @Prop() propFilters!: MilkFilters;
  @Prop() maxVolume!: number;
  @Prop() maxCapacity!: number;
  @Prop() width!: number;
  @Prop() page!: string;

  public selectedSiteFilters: {
    field: CustomField;
    selection: string[];
  }[] = [];
  public selectedVatFilters: {
    field: CustomField;
    selection: string[];
  }[] = [];
  public selectedFilters = new MilkFiltersClass().filters as MilkFilters;
  public showPercent = false;
  public updated = false;
  public filtersOnOpen: MilkFilters = cloneDeep(this.selectedFilters);

  public handleOpen() {
    this.filtersOnOpen = cloneDeep(this.selectedFilters);
  }

  public handleClose() {
    this.selectedFilters = cloneDeep(this.filtersOnOpen);
  }

  mounted() {
    if (this.propFilters) {
      this.selectedFilters = cloneDeep(this.propFilters);
    }
    if (this.selectedPropSiteFilters) {
      this.selectedFilters.customSiteFilters = this.selectedPropSiteFilters;
    }
    if (this.selectedPropVatFilters) {
      this.selectedFilters.customEntityFilters = this.selectedPropVatFilters;
    }
    if (this.selectedFilters.capacityRange == null) {
      this.selectedFilters.capacityRange = [0, this.maxCapacity];
    }
    if (this.selectedFilters.volumeRange == null) {
      this.selectedFilters.volumeRange = [0, this.maxVolume];
    }
  }

  @Watch('selectedPropSiteFilters')
  sitePropFilterChanged() {
    if (this.selectedPropSiteFilters) {
      this.selectedFilters.customSiteFilters = this.selectedPropSiteFilters;
    }
  }

  @Watch('selectedPropVatFilters')
  tankPropFilterChanged() {
    if (this.selectedPropVatFilters) {
      this.selectedFilters.customEntityFilters = this.selectedPropVatFilters;
    }
  }

  @Watch('propFilters', { deep: true })
  filtersUpdated() {
    if (this.propFilters) {
      this.selectedFilters = cloneDeep(this.propFilters);
      if (
        this.selectedFilters.volumePercent[0] > 0 ||
        this.selectedFilters.volumePercent[1] < 100
      ) {
        this.showPercent = true;
      }
    }
    if (this.selectedFilters.capacityRange == null) {
      this.selectedFilters.capacityRange = [0, this.maxCapacity];
    }
    if (this.selectedFilters.volumeRange == null) {
      this.selectedFilters.volumeRange = [0, this.maxVolume];
    }
  }

  @Watch('maxVolume')
  maxVolumeLoaded(value: number, oldValue: number) {
    if (this.selectedFilters.volumeRange[1] == oldValue) {
      this.selectedFilters.volumeRange[1] = value;
    } else if (this.selectedFilters.volumeRange[1] == 0) {
      this.selectedFilters.volumeRange[1] = this.maxVolume;
    }
  }

  @Watch('maxCapacity')
  maxCapacityLoaded(value: number, oldValue: number) {
    if (this.selectedFilters.capacityRange[1] == oldValue) {
      this.selectedFilters.capacityRange[1] = value;
    } else if (this.selectedFilters.capacityRange[1] == 0) {
      this.selectedFilters.capacityRange[1] = this.maxCapacity;
    }
  }

  get listSiteCustomFields() {
    const filtered = JSON.parse(
      JSON.stringify(CustomStore.customFields ?? [])
    )?.filter(
      (item: CustomField) => item.type == 'List Entry' && item.filterable
    );
    return filtered?.map((item: CustomField) => {
      return {
        ...item,
        listOptions: item.listOptions?.sort((a, b) => {
          if (a != null && b != null) {
            return a.toLowerCase().localeCompare(b.toLowerCase());
          } else if (a != null && b == null) {
            return -1;
          } else {
            return -1;
          }
        })
      };
    });
  }
  get listVatCustomFields() {
    const filtered = JSON.parse(
      JSON.stringify(CustomStore.customEntityFields)
    )?.filter(
      (item: CustomField) => item.type == 'List Entry' && item.filterable
    );
    return filtered.map((item: CustomField) => {
      return {
        ...item,
        listOptions: item.listOptions?.sort((a, b) => {
          if (a != null && b != null) {
            return a.toLowerCase().localeCompare(b.toLowerCase());
          } else if (a != null && b == null) {
            return -1;
          } else {
            return -1;
          }
        })
      };
    });
  }

  public isSelected(field: CustomField, selection: string, level: string) {
    let includes = false;
    if (level == 'site') {
      this.selectedFilters.customSiteFilters?.forEach(filter => {
        if (
          filter.field.name == field.name &&
          filter.selection.includes(selection)
        ) {
          includes = true;
        }
      });
    } else if (level == 'tank') {
      this.selectedFilters.customEntityFilters?.forEach(filter => {
        if (
          filter.field.name == field.name &&
          filter.selection.includes(selection)
        ) {
          includes = true;
        }
      });
    }
    return includes;
  }

  public addFilter(field: CustomField, selection: string, level: string) {
    this.updated = true;
    let includes = false;
    let selectionAlreadyInField = false;
    if (level == 'site') {
      this.selectedFilters.customSiteFilters?.forEach(filter => {
        if (filter.field.name == field.name) {
          selectionAlreadyInField = true;
          if (filter.selection.includes(selection)) {
            includes = true;
          }
        }
      });
      if (!includes) {
        if (selectionAlreadyInField) {
          this.selectedFilters.customSiteFilters
            .find(filter => filter.field.name == field.name)
            ?.selection.push(selection);
        } else {
          this.selectedFilters.customSiteFilters.push({
            field: field,
            selection: [selection]
          });
        }
      } else {
        const selectedFilter = this.selectedFilters.customSiteFilters.find(
          filter => filter.field.name == field.name
        );
        if (
          selectedFilter?.selection &&
          selectedFilter?.selection.length == 1
        ) {
          this.selectedFilters.customSiteFilters = this.selectedFilters.customSiteFilters.filter(
            filter => filter.field.name != field.name
          );
        } else if (selectedFilter?.selection) {
          selectedFilter.selection = selectedFilter.selection.filter(
            select => selection != select
          );
        }
      }
    } else if (level == 'tank') {
      this.selectedFilters.customEntityFilters?.forEach(filter => {
        if (filter.field.name == field.name) {
          selectionAlreadyInField = true;
          if (filter.selection.includes(selection)) {
            includes = true;
          }
        }
      });

      if (!includes) {
        if (selectionAlreadyInField) {
          this.selectedFilters.customEntityFilters
            .find(filter => filter.field.name == field.name)
            ?.selection.push(selection);
        } else {
          this.selectedFilters.customEntityFilters.push({
            field: field,
            selection: [selection]
          });
        }
      } else {
        const selectedFilter = this.selectedFilters.customEntityFilters.find(
          filter => filter.field.name == field.name
        );
        if (
          selectedFilter?.selection &&
          selectedFilter?.selection.length == 1
        ) {
          this.selectedFilters.customEntityFilters = this.selectedFilters.customEntityFilters.filter(
            filter => filter.field.name != field.name
          );
        } else if (selectedFilter?.selection) {
          selectedFilter.selection = selectedFilter.selection.filter(
            select => selection != select
          );
        }
      }
    }
  }

  public updateAgeFilter(newAge: number) {
    if (newAge == this.selectedFilters.age) {
      this.selectedFilters.age = 0;
    } else {
      this.selectedFilters.age = newAge;
    }
  }

  public updateRiskFilter(newRisk: number) {
    if (newRisk == this.selectedFilters.risk) {
      this.selectedFilters.risk = 0;
    } else {
      this.selectedFilters.risk = newRisk;
    }
  }

  public updateComplianceFilter(newComplianceMethod: string) {
    this.updateArrayFilter(newComplianceMethod, 'complianceMethod');
  }

  public updateArrayFilter(
    newFilterValue: string,
    filter: 'complianceMethod' | 'score'
  ) {
    if (newFilterValue == '') {
      this.selectedFilters[filter] = [];
    } else {
      if (this.selectedFilters[filter].includes(newFilterValue)) {
        this.selectedFilters[filter] = this.selectedFilters[filter].filter(
          method => method != newFilterValue
        );
      } else {
        this.selectedFilters[filter].push(newFilterValue);
      }
    }
  }

  public applyFilters(applyAndSave = false) {
    const alteredFilters: SavedMilkFilters = JSON.parse(
      JSON.stringify(this.selectedFilters)
    );
    if (this.showPercent) {
      alteredFilters.volumeRange[0] = 0;
      alteredFilters.volumeRange[1] = null;
      this.selectedFilters.volumeRange[0] = 0;
      this.selectedFilters.volumeRange[1] = this.maxVolume;
    } else {
      alteredFilters.volumePercent[0] = 0;
      alteredFilters.volumePercent[1] = 100;
      this.selectedFilters.volumePercent[0] = 0;
      this.selectedFilters.volumePercent[1] = 100;
    }
    if (this.selectedFilters.volumeRange[1] == this.maxVolume) {
      alteredFilters.volumeRange[1] = null;
    }
    if (this.selectedFilters.capacityRange[1] == this.maxCapacity) {
      alteredFilters.capacityRange[1] = null;
    }
    if (this.selectedFilters.score == undefined) {
      alteredFilters.score = [];
    }

    this.$emit(
      'moreFiltersUpdated',
      alteredFilters,
      this.selectedFilters,
      applyAndSave
    );
    this.closeFiltersModal();
  }
  public clearCustomFilters() {
    this.selectedFilters = {
      age: 0,
      volumePercent: [0, 100],
      volumeRange: [0, this.maxVolume],
      capacityRange: [0, this.maxCapacity],
      risk: 0,
      score: [],
      onlySupply: false,
      customSiteFilters: [],
      customEntityFilters: [],
      temperature: '',
      hideRepair: false,
      complianceMethod: []
    };
    this.$emit('clearFilters');
    this.applyFilters(false);
    this.closeFiltersModal();
  }

  public closeFiltersModal() {
    this.$root.$emit('bv::hide::modal', 'milkFiltersModal', '#btnHide');
  }
}
