/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ComplianceSettings } from '@/store/models/MilkScore';
import { MilkMetrics, Product } from '@/store/models/Product';
import { TableColumnFormatters } from '@/store/models/TableColumn';
import Vue from 'vue';
import { hours } from '../DateUtils';
import {
  formatAgeOfMilkDisplay,
  formatLastCollectionDateDisplay,
  formatLastMilkingDisplay,
  formatMilkVolumeDisplay
} from '../formatters/DisplayValueFormatters';
import { complianceMethodLabel, getMethod } from '../MilkScoreUtils';
import { GetDefaultTableColumns } from '../TableColumns';
import { SetVisibilityOfTableColumns } from '../TableColumnVisibility';
import { AllowedField, MilkAllowedField } from '../types/AllowedFields';
import {
  GetVisibleCustomColumnsFormatters,
  tableColumnFormatters
} from './TableFields';

export const milkSpecificTableColumnsFormatters: {
  [key in MilkAllowedField]: TableColumnFormatters;
} = {
  [MilkAllowedField.SUPPLIER_NUMBER]: {
    name: MilkAllowedField.SUPPLIER_NUMBER,
    label: () => 'Supply #',
    visible: true,
    width: 120,
    filterable: false,
    searchable: true,
    sortable: true,
    searchKey: (item: Product) => {
      return item.supplierNumber;
    },
    sortKey: (item: Product, sortType: 'child' | 'parent') => {
      //Sort product level by supply num and entities by entity name
      return sortType == 'child'
        ? item.name?.toLowerCase()
        : +item.supplierNumber;
    },
    parentFormattedValue: (item: Product, entity: any) => {
      return item.supplierNumber;
    },
    childFormattedValue: (item: Product, entity: any) => {
      return ''; //blank
    },
    textStyling: 'textOverflowEllipsis'
  },
  [MilkAllowedField.VOLUME]: {
    name: MilkAllowedField.VOLUME,
    label: () => 'Volume (L)',
    visible: true,
    width: 140,
    filterable: false,
    searchable: false,
    sortable: true,
    sortKey: (item: Product, sortType: 'child' | 'parent') => {
      //Sort product level by combined volume and entities by their volume
      return (item.metrics as MilkMetrics).containsMilk
        ? (item.metrics as MilkMetrics).vatVolume
        : 0;
    },
    parentFormattedValue: (item: Product, entity: any) => {
      return formatMilkVolumeDisplay(item);
    },
    childFormattedValue: (item: Product, entity: any) => {
      return formatMilkVolumeDisplay(entity);
    }
  },
  [MilkAllowedField.AGE]: {
    name: MilkAllowedField.AGE,
    label: () => 'Age',
    visible: true,
    width: 100,
    filterable: true,
    searchable: false,
    sortable: true,
    sortKey: (item: Product, sortType: 'child' | 'parent') => {
      //Sort product level by combined age and entities by their age
      return (item.metrics as MilkMetrics).containsMilk &&
        (item.metrics as MilkMetrics).milkEnteredVat
        ? hours((item.metrics as MilkMetrics).milkEnteredVat)
        : undefined;
    },
    parentFormattedValue: (item: Product, entity: any) => {
      return formatAgeOfMilkDisplay(item, 'parent');
    },
    childFormattedValue: (item: Product, entity: any) => {
      return formatAgeOfMilkDisplay(entity, 'child');
    }
  },
  [MilkAllowedField.LAST_MILKING]: {
    name: MilkAllowedField.LAST_MILKING,
    label: () => 'Last Milking',
    visible: true,
    width: 160,
    filterable: false,
    searchable: false,
    sortable: false,
    parentFormattedValue: (item: Product, entity: any) => {
      return formatLastMilkingDisplay(item);
    },
    childFormattedValue: (item: Product, entity: any) => {
      return formatLastMilkingDisplay(entity);
    },
    parentComponent: () => 'LastMilkingDisplay',
    childComponent: () => 'LastMilkingDisplay'
  },
  [MilkAllowedField.LAST_PICKUP]: {
    name: MilkAllowedField.LAST_PICKUP,
    label: () => 'Last Collection',
    visible: true,
    width: 160,
    filterable: false,
    searchable: false,
    sortable: false,
    parentFormattedValue: (item: Product, entity: any) => {
      return formatLastCollectionDateDisplay(item);
    },
    childFormattedValue: (item: Product, entity: any) => {
      return formatLastCollectionDateDisplay(entity);
    }
  },
  [MilkAllowedField.TEMPERATURE]: {
    name: MilkAllowedField.TEMPERATURE,
    label: () => 'Below 6°C',
    visible: true,
    width: 120,
    filterable: true,
    searchable: false,
    sortable: false,
    parentFormattedValue: (item: Product, entity: any) => {
      return ''; //SPECIAL - icons
    },
    childFormattedValue: (item: Product, entity: any) => {
      return ''; //SPECIAL - icons
    },
    parentComponent: () => 'TemperatureIcon',
    childComponent: () => 'TemperatureIcon'
  },
  [MilkAllowedField.COLLAPSE]: {
    name: MilkAllowedField.COLLAPSE,
    label: () => '',
    visible: true,
    width: 40,
    filterable: false,
    searchable: false,
    sortable: false,
    parentFormattedValue: (item: Product, entity: any) => {
      return (item.entities?.length ?? 0) > 1 ? '' : '-';
    },
    childFormattedValue: (item: Product, entity: any) => {
      return '';
    },
    parentComponent: () => 'Collapsible',
    columnStyling: 'pl-2'
  },
  [MilkAllowedField.VOLUME_CAPACITY]: {
    name: MilkAllowedField.VOLUME_CAPACITY,
    label: () => 'Volume',
    visible: true,
    width: 200,
    filterable: false,
    searchable: false,
    sortable: false,
    parentFormattedValue: (item: Product, entity: any) => {
      return '';
    },
    childFormattedValue: (item: Product, entity: any) => {
      return '';
    },
    parentComponent: () => 'VolumeCapacityField'
  },
  [MilkAllowedField.COMPLIANCE_METHOD]: {
    name: MilkAllowedField.COMPLIANCE_METHOD,
    label: () => 'Compliance',
    visible: true,
    width: 100,
    filterable: true,
    searchable: false,
    sortable: false,
    parentFormattedValue: (item: Product, entity: any) => {
      return complianceMethodLabel(
        getMethod(item.entityCustomSettingsData as ComplianceSettings)
      );
    },
    childFormattedValue: (item: Product, entity: any) => {
      return '';
    }
  }
};

export const milkTableColumnsFormatters = {
  ...tableColumnFormatters,
  ...milkSpecificTableColumnsFormatters
};

export function GetMilkTableColumnFormatters(
  fields: (MilkAllowedField | AllowedField)[]
) {
  const baseColumnsWithFormatters = fields.map(column => {
    return milkTableColumnsFormatters[column];
  });
  return baseColumnsWithFormatters;
}

export async function GetVisibleMilkTableColumnFormatters(
  fields: (MilkAllowedField | AllowedField)[],
  addCustomFields: boolean
) {
  const defaultColumnsWithVisibility = GetDefaultTableColumns();
  const baseColumnsWithFormatters = SetVisibilityOfTableColumns(
    fields.map(column => {
      return milkTableColumnsFormatters[column];
    })
  ).filter(column => {
    if (column.name == AllowedField.VOLUME_PERCENT_BAR) {
      return defaultColumnsWithVisibility[MilkAllowedField.VOLUME].visible;
    }
    return column.visible;
  });

  if (addCustomFields) {
    const customColumnsWithFormatters = await GetVisibleCustomColumnsFormatters();
    return [...baseColumnsWithFormatters, ...customColumnsWithFormatters];
  } else {
    return baseColumnsWithFormatters;
  }
}

export function GetDefaultMilkColumns() {
  return (
    Object.fromEntries(
      Object.entries(milkTableColumnsFormatters).filter(column => {
        return column[0].includes('Default');
      })
    ) ?? []
  );
}
